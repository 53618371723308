<template>
  <div class="page page--detail">
    <div
      class="back"
      v-if="lastKeyword">
      回到 <router-link :to="lastListPageRoute">{{lastKeyword}}</router-link> 的結果
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data() {
    return {
      lastKeyword: ''
    }
  },
  computed: {
    ...mapState([
      'keyword',
      'moduleType'
    ]),
    lastListPageRoute () {
      return {
        name: 'demo-list',
        query: {
          keyword: this.lastKeyword,
          moduletype: this.moduleType
        }
      }
    },
  },
  created () {
    this.lastKeyword = this.keyword
  }
}
</script>
